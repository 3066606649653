<template>
  <div>
    <div id="map-info-container" v-if="value">
      <div>
        <div id="map-info">
          <span v-if="value.zoom && showMore" :class="{ 'mr-4': $vuetify.breakpoint.lgAndUp }">
            Zoom: {{ value.zoom }}
            <v-slide-y-transition>
              <br v-if="$vuetify.breakpoint.mdAndDown" />
            </v-slide-y-transition>
          </span>
          <span v-if="value.lngLat"> GPS: {{ value.lngLat }} </span>
          <base-tooltip :color="copied ? 'success' : 'primary'" bottom v-if="value.click && showMore">
            <template #activator="{ on }">
              <span
                v-on="on"
                id="mapCoordinates"
                v-clipboard:success="onCopy"
                v-clipboard:copy="value.lngLat"
                class="clicked"
                :class="{ 'ml-4': $vuetify.breakpoint.lgAndUp }"
              >
                <br v-if="$vuetify.breakpoint.mdAndDown" />
                Clicked: {{ value.click }}
              </span>
            </template>
            <span v-if="copied">Copied</span>
            <span v-else> Click to copy coordinates </span>
          </base-tooltip>
          <div
            class="d-lg-inline d-flex justify-space-between"
            v-if="showMore"
            :class="{ 'ml-4': $vuetify.breakpoint.lgAndUp }"
          >
            <span v-if="value.pitch"> Pitch: {{ value.pitch }}</span>
            <span v-if="value.bearing" :class="{ 'ml-4': !!value.pitch }">
              Bearing: {{ value.bearing }}</span
            >
          </div>
        </div>
      </div>
      <v-icon
        class="show-more"
        @click="toggleShowMore()"
        :color="showMore ? 'primary' : 'secondary'"
        >{{ showMore ? 'mdi-dots-vertical-circle' : 'mdi-dots-vertical' }}</v-icon
      >
    </div>
    <div>
      <slot :show="showMore"></slot>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard);
export default {
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      click: '',
      copied: false,
      showMore: false,
      dblclick: null,
      cursor: localStorage.getItem('mapCursor') || 'grab',
      pitch: '',
      bearing: ''
    };
  },

  created() {
    const model = !!localStorage.getItem('mapShowMore') || false;
    this.showMore = model;
    this.$emit('update:showMore', this.showMore);
  },

  mounted() {
    document.addEventListener('dblclick', this.handleDblClick);
    this.setCursor();
  },
  beforeDestroy() {
    document.removeEventListener('dblclick', this.handleDblClick);
  },
  methods: {
    toggleShowMore() {
      this.showMore = !this.showMore;
      this.$emit('update:showMore', this.showMore);
      if (!this.showMore) {
        this.cursor = 'grab';
      }
      this.setCursor();
      if (this.showMore) {
        localStorage.setItem('mapShowMore', true);
      } else {
        localStorage.removeItem('mapShowMore');
      }
    },
    setCursor() {
      localStorage.setItem('mapCursor', this.cursor);
      this.$emit('update:cursor', this.cursor);
      try {
        document.querySelectorAll(
          '.mapboxgl-canvas-container.mapboxgl-interactive'
        )[0].style.cursor = this.cursor;
      } catch {
        //
      }
    },
    handleDblClick() {
      if (this.showMore && this.cursor === 'grab') {
        this.cursor = 'crosshair';
      } else {
        this.cursor = 'grab';
      }
      this.setCursor();
    },
    onCopy() {
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
#map-info-container {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 2;
}
#map-info {
  background: hsla(0, 0%, 100%, 0.5);
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  padding: 0px 5px 0px 5px;
  z-index: 9999;
}
.show-more {
  z-index: 9999;
}
.clicked {
  color: rgb(22, 104, 67);
}
</style>
